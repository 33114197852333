.card{
    border-radius: 5pt;
    border-width: medium;
    border-style: solid;
    margin-top: 0px;
    box-shadow: 0px 0px 0px #888888;

}
.card-header {
    background-color: white;
    border-bottom-color:#162346;;
    border-bottom-width: 3px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: small;
    color: #162346;
}

.card-header-alert {
    background-color: #cc5500;
    border-color: #cc5500;
}

.card-footer {
    border-radius: 15pt;
    border-top-width: 3px;
    border-top-style: solid;
    margin-left: 20px;
    margin-right: 20px;
}


.card-body {
    border-color: #337ab7;
    border-width: thin;
    border-style: none;
    font-size: 14px;
    font-weight: 400;

}

.card-body-alert {
    border-color: #cc5500;
}

.card-body-grid {
    margin: 0px,0px,0px,0px !important;
    padding: 0.1em 0.1em !important;
}

.card {
    border-width: thin;
    border-style: none;
    margin-top: 10px;
    box-shadow: 5px 10px 10px #888888;
}


.card-button{
    width: 100%
}

.k-tabstrip .k-content {
    border-width: 5px 0 0;
    padding: 0px 0px 0px;
    background-color: white;
    min-height: 300px;
}
.k-tabstrip-items-wrapper {
    background-color: white;
    border-radius: 10px;
}


.k-tabstrip {
    background: none transparent;
    margin-top: 20px;
    box-shadow: 5px 10px 10px #888888;
    border-radius: 10px;
    min-height: 300px;
}
.k-tabstrip .k-item{
    font-weight: 480;
}
.k-tabstrip-items .k-item .k-link {
    color: #62BCBD;
}
.k-tabstrip .k-item.k-state-disabled{
    color: #888888;
}

/* fieldset {
    border: 1px solid #394a5a;
    padding: 0 10px 10px 10px;
    border-radius: 10px;
}
 */
/* legend {
    padding: 0px 5px ;
    margin-left: 10px;
    font-size: 16x;
    width: auto !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
    float: none; padding: inherit;
}
 */



table {
    table-layout: fixed;
}

.sprinkles {
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: #337ab7;
    padding-left: 5px;
}



b {
    font-size: 14px;
}
form{
    font-size: 14px;
    font-weight: 400;
}
.dash-card{
    border-radius: 15pt;
    border-width: medium;
    border-style: solid;
    margin-top: 0px;
    box-shadow: 0px 0px 0px #888888;
}
.dash-card-footer{
    border-radius: 15pt;
    border-top-width: 3px;
    border-top-style: solid;
    margin-left: 20px;
    margin-right: 20px;
    
}

.dash-card-header{
    background-color: white;
    border-bottom-width: 3px;
    margin-left: 20px;
    margin-right: 20px;
}

.k-animation-container {
    z-index: 100020 !important
  }
  
  .k-dialog-wrapper {
    z-index: 100030 !important
  }