.center-align {
    text-align: center !important;
}

.right-align{
    text-align: right !important;
}

.psn-green{
    color: #62BCBD;
}
.psn-blue{
    color: #162346;
}
